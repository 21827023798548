import Navigation from './navigation.tsx'

export default function Header({
	title,
	subtitle,
}: {
	title: string
	subtitle: string
}) {
	return (
		<div
			id="header"
			className="relative z-30"
			style={{
				backgroundImage: `url(/assets/background.svg)`,
				backgroundRepeat: 'repeat',
			}}>
			<div className="relative z-30 flex h-full w-full flex-col space-y-[144px] bg-gradient-to-r from-green-100/60 to-green-100/50">
				<Navigation />
				<header className="container flex w-full flex-col space-y-4 pb-20">
					<div className="flex flex-row place-items-center space-x-5">
						<h2 className="text-[24px] font-extralight leading-[43px] tracking-wider text-white">
							{title}
						</h2>
						<hr className="h-[3px] w-[75px] bg-copper-100" />
					</div>
					<h1 className="text-[60px] font-light leading-[65px] tracking-wide text-white">
						{subtitle}
					</h1>
				</header>
			</div>
		</div>
	)
}
